import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import patientReducer from './slices/patient/patientSlice';
import cabinetReducer from './slices/cabinet/cabinetSlice';
import laboratorieReducer from './slices/laboratorie/laboratorieThunkSlice';
import permissionReducer from './slices/permission/permissionSlice';
import roleReducer from './slices/role/roleSlice';
import entityReducer from './slices/entity/entitlySlice';
import actionReducer from './slices/action/actionSlice';
import materialReducer from './slices/materiaux/materiauxSlice';
import prothesistReducer from './slices/prothesist/prothesistSlice';
import typeReducer from './slices/type/typeSlice';
import optionReducer from './slices/option/optionSlice';
import treatmentReducer from './slices/treatment/treatmentsSlice';
import notificationReducer from './slices/notifications/notificationSlice';
import dentReducer from './slices/prothese/protheseSlice';
import commandReducer from './slices/command/commandSlice';
import articleReducer from './slices/article/ArticleSlice';
import cartePatientReducer from './slices/cartePatient/cartePatientSlice';
import dentistReducer from './slices/dentist/dentistSlice';
// ----------------------------------------------------------------------
export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};
export const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};
const rootReducer = combineReducers({
    patient: patientReducer,
    cabinet: cabinetReducer,
    laboratorie: laboratorieReducer,
    permission: permissionReducer,
    role: roleReducer,
    action: actionReducer,
    entity: entityReducer,
    material: materialReducer,
    prothesist: prothesistReducer,
    type: typeReducer,
    option: optionReducer,
    notifications: notificationReducer,
    treatment: treatmentReducer,
    dent: dentReducer,
    command: commandReducer,
    article: articleReducer,
    cart: cartePatientReducer,
    dentist: dentistReducer,
});
export default rootReducer;
